import { Button, Page, Spinner, Variant } from '@lib/components'
import { Currency, displayCurrency, isCampaignInCurrentYearOrPrevious } from '@lib/services'
import { ActiveCampaign, canScheduleBeAutoEnrolled, Card, fetchCheckoutSession, getPlatformStripe, GivematchShareSection, loadSchedule, MTDtracking, NotFoundError, setCancelled, PaymentStatus as Status, updateAutoEnroll, type Schedule as ScheduleType } from '@shamaazi/mytennights'
import { DateTime } from 'luxon'
import { type FC } from 'react'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { Link, useParams } from 'react-router-dom'
import { MTDHeader } from '~/components/MTDHeader'

import { useAuth } from '@lib/hooks'
import { ScheduleTestIds } from '@lib/testing'

const getStatusColor = (status: Status): { bg: string, text: string } => {
  if (status === Status.Skipped) {
    return { bg: 'bg-mtd-blue-200', text: 'text-mtd-blue-200' }
  }
  if (status === Status.Cancelled) {
    return { bg: 'bg-mtd-gray-700', text: 'text-mtd-red' }
  }
  if (status === Status.Failed) {
    return { bg: 'bg-mtd-red', text: 'text-mtd-red' }
  }
  if (status === Status.Succeeded) {
    return { bg: 'bg-mtd-blue', text: 'text-mtd-blue' }
  }
  return { bg: 'bg-mtd-gray-700', text: 'text-mtd-gray-700' }
}

export const Schedule: FC = () => {
  const { scheduleID } = useParams() as { scheduleID: string }
  const { user } = useAuth()
  const queryClient = useQueryClient()

  const { isLoading, isError, error, data: schedule } = useQuery<ScheduleType>(['schedule', scheduleID], async () => await loadSchedule(scheduleID), { retry: 0 })

  const {
    mutate: changeScheduleCancelled,
    isError: isCancelError,
    isLoading: isCancelLoading
  } = useMutation(async (): Promise<ScheduleType> => {
    const cancelled = !schedule!.cancelled
    if (cancelled) {
      MTDtracking.cancelDonation(user?.email, schedule!.charity_name, schedule!.charity_id, schedule!.total, schedule!.currency, schedule!.causes)
    } else {
      MTDtracking.reactivateDonation(user?.email, schedule!.charity_name, schedule!.charity_id, schedule!.total, schedule!.currency, schedule!.causes)
    }
    return await setCancelled(scheduleID, cancelled)
  }, {
    onSuccess: (data) => {
      queryClient.setQueryData(['schedule', scheduleID], data)
      queryClient.invalidateQueries(['schedule', scheduleID]).catch(() => { })
    }
  })

  const {
    mutate: setAutoEnrollState,
    isError: isAutoEnrollCancelError,
    isLoading: isAutoEnrollCancelLoading
  } = useMutation(async (): Promise<ScheduleType> => await updateAutoEnroll(scheduleID, !(schedule?.auto_enroll ?? true)), {
    onSuccess: async (data: ScheduleType) => {
      queryClient.setQueryData(['schedules', scheduleID], data)
      await queryClient.invalidateQueries(['schedule', scheduleID])
    }
  })

  const handleClick = async (): Promise<void> => {
    if (!schedule || !user?.email) {
      return
    }
    try {
      // Call your backend to create the Checkout session.
      const sessionId: string = await fetchCheckoutSession(scheduleID, schedule, user?.email)
      // When the customer clicks on the button, redirect them to Checkout.
      const stripe = await getPlatformStripe()
      const result = await stripe?.redirectToCheckout({ sessionId })
      if (result?.error) {
        console.error('Error redirecting to Checkout:', result.error)
      }
    } catch (error) {
      console.error('Error handling payment:', error)
    }
  }

  const handleClickWrapper = (): void => {
    handleClick().catch(error => {
      console.error('Error handling payment:', error)
    })
  }

  const splitBreakdown = (schedule?.payments ?? []).map(({ number, total, date, status }) => {
    const color = getStatusColor(status)

    return <div key={number}
      className={`${status === Status.Skipped ? 'opacity-80' : ''} h-60 font-medium bg-mtd-gray-100 rounded flex flex-col items-center justify-center text-center`}>
      <span className={`${color.bg} h-14 w-14 rounded-full text-white text-xl flex items-center justify-center`}>{number}</span>
      <span className={`${color.text} text-xs mt-3`}>{date.toLocaleString({ weekday: 'short', month: 'long', day: 'numeric' })}</span>
      <span className={`${color.text} text-sm my-3`}>Payment {status}</span>
      <span className={color.text}>{status !== Status.Skipped ? displayCurrency({ amount: total, currency: schedule?.currency ?? Currency.GBP }) : 'Unavailable'}</span>
      {status !== Status.Skipped && <Link to={`/account/schedule/${scheduleID}/payment/${number}`} className="mt-2 text-xs underline"
        onClick={() => MTDtracking.viewScheduleDetails(user?.email)}>View Details</Link>}
    </div>
  })
  return <main>
    <MTDHeader />
    <div className="mx-4 my-6 underline">
      <Link to="/account/schedules">&lt; Back to My Schedules</Link>
    </div>

    <Page data-test-id={ScheduleTestIds.schedulePage} className="px-10 py-10 font-medium lg:grid lg:gap-x-20 lg:grid-cols-fr-auto">
      <div>
        <h1 className="text-xl text-mtd-blue">Schedule</h1>
        {isLoading && <div className="flex justify-center mt-20">
          <Spinner />
        </div>}
        {isError && error instanceof NotFoundError && <div className="mt-10">
          <h2>Sorry, we couldn&apos;t find this donation.<br />Please check you are logged in as the correct email address</h2>
        </div>}
        {isError && !(error instanceof NotFoundError) && <div className="mt-10">
          <h2>Sorry, we weren't able to load your schedule. Please try again later and if
            the issue persists, <Link className="underline text-mtd-blue" to="/contact">contact us</Link>
          </h2>
        </div>}
        {!isLoading && !isError && schedule !== undefined && <>
          <p className="my-4 text-mtd-blue-900">Created
            at {schedule?.created?.toLocaleString(DateTime.TIME_24_SIMPLE)} on {schedule?.created?.toLocaleString(DateTime.DATE_MED)}</p>
          {schedule.cancelled && <p className="my-2 text-sm text-mtd-red">Cancelled</p>}
          <hr className="text-mtd-gray-300" />
          <section className="items-center my-10 grid gap-y-6 gap-x-10 grid-cols-auto-fr">
            <span className="text-sm">Charity</span>
            <span className="text-right text-mtd-blue-900">{schedule.charity_name}</span>
            <span className="self-start mt-2 text-sm">Causes</span>
            <div>
              {schedule.causes.map(c => <div key={c.name} className="mt-2 grid gap-x-6 grid-cols-fr-auto">
                <span className="text-right">{c.name}</span>
                <span className="text-right text-mtd-blue-900">{displayCurrency({ amount: c.amount, currency: schedule.currency })}</span>
              </div>)}
            </div>
            <span className="text-sm">MyTenDays Donation</span>
            <span className="text-right text-mtd-blue-900">{displayCurrency({ amount: schedule.tip, currency: schedule.currency })}</span>
            <span className="text-sm">Transaction Fees</span>
            <span className="text-right text-mtd-blue-900">{displayCurrency({ amount: schedule.payFees ? schedule.fee : 0, currency: schedule.currency })}</span>
            <hr className="col-span-2 text-mtd-gray-200" />
            <span className="text-sm">Total</span>
            <span className="font-bold text-right text-mtd-blue-900">{displayCurrency({ amount: schedule.total, currency: schedule.currency })}</span>
          </section>

          <GivematchShareSection schedule={schedule} platform={Variant.mtd} />
          <hr className="my-10 col-span-2 text-mtd-gray-200" />

          <section>
            <h2 className="text-mtd-blue">Here&apos;s Your Breakdown</h2>
            <div className="mt-10 grid gap-3 grid-cols-auto-min-30 md:grid-cols-5 lg:grid-cols-auto-min-30 xl:grid-cols-5">
              {splitBreakdown}
            </div>
          </section>
        </>}
      </div>
      <div className="flex flex-col">
      {isLoading && <section className="flex items-center justify-center p-7"><Spinner /></section>}
          {!isLoading && !isError && schedule !== undefined && <>
        <Card variant="mtd" className="mt-20 -mx-6 lg:mx-0 lg:w-96 md:mt-0">
            {schedule.cardBrand !== '' && <section className="p-7">
              <div>Paying with card: {schedule.cardBrand} ending {schedule.last4CardDigits}</div>
              <div className="mt-3">Expires {schedule.cardExpiry}</div>
            </section>}
            {schedule.cardBrand === '' && <section className="p-7">
              <div>Paying with unknown card</div>
            </section>}
            {schedule.actionable && <section className="flex justify-center border-t p-7 border-mtd-gray-200">
              {!schedule.cancelled &&
                <Button loading={isCancelLoading} disabled={isCancelLoading} onClick={() => changeScheduleCancelled()} variant="mtd-tertiary" className="text-mtd-red">CANCEL
                  DONATIONS</Button>}
              {schedule.cancelled &&
                <Button loading={isCancelLoading} disabled={isCancelLoading} onClick={() => changeScheduleCancelled()} variant="mtd-primary">REACTIVATE</Button>}
              {isCancelError && <p className="text-sm text-mtd-red">Sorry, we were unable to do this</p>}
            </section>}
        </Card>
        <Card variant="mtd" className="mt-4 -mx-6 lg:mx-0 lg:w-96">
          {schedule.cardBrand !== '' && <section className="p-7">
            <div>You are currently <span className=' font-bold'>{schedule.auto_enroll ? ' opt-in ' : 'not opt-in'}</span>  for automation schedule for next year </div>
          </section>}
          {canScheduleBeAutoEnrolled(schedule, ActiveCampaign.myTenDays) && <section className="flex justify-center border-t p-7 border-mtn-gray-300">
            {schedule.auto_enroll && <Button loading={isAutoEnrollCancelLoading} disabled={isAutoEnrollCancelLoading} onClick={() => setAutoEnrollState()} variant="mtn-tertiary"
              className="text-mtn-red">CANCEL NEXT YEAR SCHEDULE </Button>}
            {!schedule.auto_enroll &&
              <Button loading={isAutoEnrollCancelLoading} disabled={isAutoEnrollCancelLoading} onClick={() => setAutoEnrollState()} variant="mtn-tertiary">OPT-IN FOR NEXT YEAR SCHEDULE</Button>}
            {isAutoEnrollCancelError && <p className="text-sm text-mtn-red">Sorry, we were unable to do this</p>}
          </section>}
        </Card>
        {(ActiveCampaign.myTenNights === schedule?.campaign || (schedule?.campaign && isCampaignInCurrentYearOrPrevious(schedule.campaign))) && (
          <Card variant="mtd" className="mt-4 -mx-6 lg:mx-0 lg:w-96">
            <section className="flex justify-center border-t p-7 border-mtn-gray-300">
              <Button id='checkout-button' variant="mtn-tertiary" onClick={handleClickWrapper}>UPDATE PAYMENT DETAILS</Button>
            </section>
          </Card>
        )}
        </>}
      </div>
    </Page>
  </main>
}
